import {useState} from 'react';
import {Helmet} from 'react-helmet';
import clsx from 'clsx';
import {useForm, FieldValues} from 'react-hook-form';
import {navigate} from 'gatsby';
import {Button, Input, Form} from '../../components/core';
import validateEmail from '../../helpers/validators/email';
import * as styles from './login.module.css';
import {MailIcon} from '../../components/core/icons';

const Login = () => {
    if (typeof window !== 'undefined') {
        if (localStorage.accountState) {
            navigate('/');
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [userNotInDB, setUserNotInDB] = useState(false);
    const {
        control,
        handleSubmit,
        getValues,
        formState: {isValid},
    } = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {
            email,
        },
    });

    const resetLogin = () => {
        setLoading(false);
        setEmail('');
        setError('');
        setUserNotInDB(false);
    };

    const sendForm = (data: FieldValues) => {
        setLoading(true);

        const formData = {
            email: data.email.toLowerCase(),
        };

        fetch(`${process.env.GATSBY_API_BASE_URL}/auth/magiclink`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then(result => {
                setEmail(result.data.email);
                setLoading(false);
            })
            .catch(err => {
                if (!err.json) {
                    setError('There was an error while sending magic link');
                    setLoading(false);

                    return;
                }

                err
                    .json()
                    .then((result: {error: {errorCode: string; message: string}}) => {
                        setLoading(false);

                        if (result.error.errorCode === 'userNotInDB') {
                            setUserNotInDB(true);

                            return;
                        }

                        setError(result.error.message);
                    });
            });
    };

    const onSubmit = (loginData: FieldValues) => {
        if (!isValid) {
            return;
        }

        sendForm(loginData);
    };

    return (
        <main className={styles.root}>
            <Helmet>
                <title>Login | LEGO IX</title>
                <link
                    rel="prefetch"
                    href="/images/account/login/list.svg"
                    as="image"
                />
                <link
                    rel="prefetch"
                    href="/images/account/login/envelop-top.svg"
                    as="image"
                />
                <link
                    rel="prefetch"
                    href="/images/account/login/envelop-bottom.svg"
                    as="image"
                />
            </Helmet>
            <div className={styles.login}>
                <img
                    src="/images/lego-logo.png"
                    className={clsx(
                        styles.logo,
                        !loading && email && styles.list,
                    )}
                    alt="LEGO logo"
                />
                {userNotInDB && (
                    <>
                        <img
                            src="/images/account/login/sad-clown.png"
                            className={styles.sadClown}
                            alt="LEGO logo"
                        />
                        <p
                            className={styles.text}
                        >
                            Oopsie! We don&apos;t have <strong className={styles.email}>{getValues().email}</strong><br/>in our database.
                        </p>
                        <br/>
                        <p
                            className={styles.text}
                        >
                            Has there been a mix-up?
                            <br/>Please contact <a href="mailto:IX@digitalvision.cz" className={styles.link}>IX@digitalvision.cz</a> if you need to get your account checked.
                        </p>
                        <br/>
                        <Button
                            size="medium"
                            onClick={resetLogin}
                            text="Back to login"
                        />
                    </>
                )}
                {!loading && error && (
                    <>
                        <h1 className={styles.title}>There was an error :(</h1>
                        <p
                            className={clsx(
                                styles.text,
                                styles.error,
                            )}
                        >
                            {error}
                        </p>
                        <Button
                            text="Try again"
                            onClick={resetLogin}
                        />
                    </>
                )}
                {!loading && email && (
                    <>
                        <div className={styles.envelopWrapper}>
                            <img
                                src="/images/account/login/list.svg"
                                className={styles.list}
                                alt="envelop list"
                            />
                            <img
                                src="/images/account/login/envelop-top.svg"
                                className={styles.envelopTop}
                                alt="envelop top part"
                            />
                            <img
                                src="/images/account/login/envelop-bottom.svg"
                                className={styles.envelopBottom}
                                alt="envelop bottom part"
                            />
                        </div>
                        <h1 className={styles.title}>Check your inbox</h1>
                        <p className={styles.text}>
                            We sent an email to<br/>
                            <span className={styles.email}>{email}</span><br/>
                            with a magic link to log in with a single click.
                        </p>
                    </>
                )}
                {!email && !userNotInDB && !error && (
                    <>
                        <h1 className={styles.title}>Welcome to IX</h1>
                        <Form>
                            <Input
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'E-mail is required',
                                    },
                                    validate: validateEmail,
                                }}
                                title="Your work e-mail"
                                name="email"
                                placeholder="name@lego.com"
                                icon={<MailIcon/>}
                            />
                            <Button
                                disabled={loading || !isValid}
                                size="medium"
                                onClick={handleSubmit(onSubmit)}
                                text="Login"
                                loading={loading}
                            />
                        </Form>
                    </>
                )}
                <a href="#" className={styles.trouble}>
                    {/* Trouble logging in? */}
                </a>
            </div>
        </main>
    );
};

export default Login;
